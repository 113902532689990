var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        {
          ref: "searchbox",
          attrs: { isShowSearch: false },
          on: { enter: _vm.getList },
        },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-func-location", {
                  attrs: {
                    type: "search",
                    plantCd: _vm.searchParam.plantCd,
                    editable: _vm.editable,
                    label: "기능위치",
                    name: "funcLocationCd",
                  },
                  model: {
                    value: _vm.searchParam.funcLocationCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "funcLocationCd", $$v)
                    },
                    expression: "searchParam.funcLocationCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-equip-class", {
                  attrs: { label: "설비유형", name: "equipmentTypeCd" },
                  model: {
                    value: _vm.searchParam.equipmentTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "equipmentTypeCd", $$v)
                    },
                    expression: "searchParam.equipmentTypeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    type: "search",
                    codeGroupCd: "WO_EQUIP_STOP_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "discardFlag",
                    label: "설비상태",
                  },
                  model: {
                    value: _vm.searchParam.discardFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "discardFlag", $$v)
                    },
                    expression: "searchParam.discardFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "equipTable",
          attrs: {
            title: "설비 목록",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: _vm.grid.height,
            selection: _vm.popupParam.type,
            rowKey: "equipmentCd",
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "discardFlag"
                    ? [
                        _c(
                          "q-chip",
                          {
                            attrs: {
                              color:
                                props.row.discardFlag == "WESC000001"
                                  ? "blue"
                                  : props.row.discardFlag == "WESC000002"
                                  ? "red"
                                  : "orange",
                              "text-color": "white",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(props.row.discardFlagName) + " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "검색", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                  _c("c-btn", {
                    attrs: { label: "선택", icon: "check" },
                    on: { btnClicked: _vm.select },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }